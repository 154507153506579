import React from 'react';
import { HeroBackground } from '@vp/digital-hero-lib';
import {
  Button,
  Link,
  BannerTitle,
  BannerDescription,
  BannerTextContainer,
  Banner,
  BannerText,
} from '@vp/swan';
import { usePathSuffix } from '@vp/digital-environment-lib';

interface HeroProps {
  mfeName: string;
  pageIndexPath: string;
  setPathSuffix: boolean;
}

const Hero = ({ mfeName, pageIndexPath, setPathSuffix }: HeroProps) => {
  const pathSuffix = usePathSuffix();

  return (
    <Banner
      textBoxHorizontalAlign="center"
      variant="full-width-image"
      height="short"
    >
      <BannerTextContainer>
        <BannerText>
          <BannerTitle component="h1">{mfeName} Page Index</BannerTitle>
          <BannerDescription>
            <Link href={`${pageIndexPath}${setPathSuffix ? pathSuffix : ''}`}>
              <Button skin="primary" size="mini" width="standard">
                Page Index
              </Button>
            </Link>
          </BannerDescription>
        </BannerText>
      </BannerTextContainer>
      <HeroBackground backgroundClassName="index-hero__background" />
    </Banner>
  );
};

export default Hero;
